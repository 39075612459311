import {useNavigate } from 'react-router-dom';
import StateManager from "../redux/StateManager";

export const useNavigation = () => {
    const navigate = useNavigate();

    const redirect = (url) => {
        if (!StateManager.isBusy())
            navigate(url);
    };

    const goBack = () => {
        if (!StateManager.isBusy())
            navigate(-1);
    };

    const reloadPage = () => {
        if (!StateManager.isBusy())
            navigate(0);
    };
    return {
        redirect,
        goBack,
        reloadPage,
    };
};
