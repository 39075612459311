import md5 from "md5"
import {readPublicKey, encryptPGPMessage} from "../business/encryption/pgp"
import {encodeBase64} from "../utils/utils";

const attachmentNamesSeparator = md5("ATTACHMENT_NAMES")
const attachmentSeparator = md5("ATTACHMENTS")

export function generateRFC822Message({from, to = [], cc = [], bcc = [], subject, body, attachments, isHtml}) {
    const boundary = 'boundary_' + new Date().getTime();
    let message = `From: ${from}\r\n`;
    if (to.length > 0) {
        message += `To: ${to.join(', ')}\r\n`;
    }
    if (cc.length > 0) {
        message += `Cc: ${cc.join(', ')}\r\n`;
    }
    if (bcc.length > 0) {
        message += `Bcc: ${cc.join(', ')}\r\n`;
    }
    message += `Subject: ${subject}\r\n`;
    message += `MIME-Version: 1.0\r\n`;

    message += `--${boundary}\r\n`;
    if (isHtml) {
        message += `Content-Type: text/html; charset="UTF-8"\r\n\r\n`;
    } else {
        message += `Content-Type: text/plain; charset="UTF-8"\r\n\r\n`;
    }
    message += `${body}\r\n\r\n`;

    if (attachments.length > 0) {
        message += `Content-Type: multipart/mixed; boundary="${boundary}"\r\n\r\n`;
    }

    attachments.forEach((file) => {
        message += `--${boundary}\r\n`;
        message += `Content-Type: ${file.type}; name="${file.name}"\r\n`;
        message += `Content-Disposition: attachment; filename="${file.name}"\r\n`;
        message += `Content-Transfer-Encoding: base64\r\n\r\n`;
        message += `${file.content}\r\n\r\n`;
    });
    message += `--${boundary}--`;
    return message;
}

export async function encryptMessage({
                                         subject,
                                         body,
                                         attachments,
                                         isHtml,
                                         publicKeys
                                     }) {
    let encryptionKeys = []
    for (let i = 0; i < publicKeys.length; i++) {
        encryptionKeys.push(await readPublicKey(publicKeys[i]))
    }

    if (isHtml) {
        body = encodeBase64(body);
        body = `BASE64_HTML_${body}`
    }

    let attachmentNames = ""

    attachments.forEach((attachmentName) => {
        attachmentNames += `${attachmentName}/`
    })

    let encryptionResults = await Promise.all([
        encryptPGPMessage(encryptionKeys, body),
        encryptPGPMessage(encryptionKeys, subject),
        encryptPGPMessage(encryptionKeys, attachmentNames),
    ])

    body = encryptionResults[0]
    subject = encryptionResults[1].replaceAll("\n", "@")
    attachmentNames = encryptionResults[2]
    body = `${body}${attachmentNamesSeparator}${attachmentNames}${attachmentNamesSeparator}${attachmentSeparator}`
    let atcEncryptPromises = []
    attachments.forEach((file) => {
        atcEncryptPromises.push(encryptPGPMessage(encryptionKeys, file.content))
    })

    let atcEncryptResults = await Promise.all(atcEncryptPromises)

    atcEncryptResults.forEach((encAttachment, key) => {
        let attachmentName = md5(attachments[key].name)
        body += attachmentName;
        body += encAttachment
        body += attachmentName;
    })
    return {body, encryptedSubject: subject}
}

export const MailFieldsForCache = {
    uid: "uid",
    from: "from",
    to: "to",
    isEncrypted: "isEncrypted",
    subject: "subject",
    body: "body",
    attachmentNames: "attachmentNames",
    words: "words"
}

export const WordIndexFieldsForCache = {
    word: "word",
    uids: "uids"
}

export const prepareBoxesNamesForCache = (boxes) => {
    let resp = []
    boxes.forEach((box) => {
        resp.push(md5(box))
    })
    return resp
}

export const WordIndexCollection = md5("words")



