import React, {useEffect, useRef, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {toast, ToastContainer} from 'react-toastify';
import {
    base64ToByteArray,
    base64ToUtf8,
    createFile,
    downloadFile,
    formatDateForMailDisplay,
    userIsLoggedIn
} from "../utils/utils";
import Navigation from "../components/navigation";
import Header from "../components/header";
import {useNavigation} from "../hooks/navigation"
import StandardTextInput from "../components/basic/StandardTextInput";

import Styles from "../styles/main.module.css";

import {fetchAttachmentData, fetchMessageBody, listBoxes, listing, listingForCache} from "../server-calls/index"
import StateManager from "../redux/StateManager";
import {CACHE_CONF, HTML_MESSAGE_IDENTIFIER, SORT_OPTIONS, SORT_ORDER, TOAST_CONTAINER_ID} from "../utils/constants";
import _ from "lodash";
import StandardDropdown from "../components/basic/StandardDropDown";

import {decryptPGPMessage} from "../business/encryption/pgp";
import {addEmailInCache, initDB} from "../idb";
import md5 from "md5";
import {Helmet} from "react-helmet-async"

export default function Mail() {
    let [isWorking, setIsWorking] = useState(false);
    let [mailData, setMailData] = useState([]);
    let [mailboxes, setMailboxes] = useState([]);
    let [sortOptions] = useState(SORT_OPTIONS)
    let [sortOption, setSortOption] = useState(SORT_OPTIONS[0].value)
    let [sortOrders] = useState(SORT_ORDER)
    let [sortOrder, setSortOrder] = useState(SORT_ORDER[0].value)
    let [currentMbox, setCurrentMailbox] = useState("INBOX")
    let [currentPage, setCurrentPage] = useState(1)
    let [totalPages, setTotalPages] = useState(1)
    let [hasNextPage, setHasNextPage] = useState(false)
    let [hasPreviousPage, setHasPreviousPage] = useState(false)
    let [pageSize] = useState(7)
    let [currentMsgAttachments, setCurrentMsgAttachments] = useState([])
    let [currentMsgUid, setCurrentMsgUid] = useState("")
    let [currentMsgFrom, setCurrentMsgFrom] = useState("")
    let [currentMsgTo, setCurrentMsgTo] = useState("")
    let [currentMsgCc, setCurrentMsgCc] = useState("")
    let [currentMsgBcc, setCurrentMsgBcc] = useState("")
    let [currentMsgDate, setCurrentMsgDate] = useState("")
    let [currentMsgSubject, setCurrentMsgSubject] = useState("")
    const attachmentContainerRef = useRef(null);
    let cacheToastId = useRef(null);
    let cacheCurrentBox = useRef("Inbox");
    let cacheCurrentBoxTotalMessages = useRef(0);
    let cacheCurrentBoxDoneMessages = useRef(0);
    let cacheStarted = useRef(false)
    const headerContainerRef = useRef(null)
    const mailActionsListRef = useRef(null)
    let [mailRendered] = useState(false)
    let [renderedMessageId] = useState(null)

    useEffect(() => {
        if (!cacheStarted.current) {
            (async () => {
                await startCache();
            })();
        }
    }, []);

    async function startCache() {
        cacheStarted.current = true
        const mailboxes = await listBoxes(StateManager.getToken())
        const dbName = StateManager.getUserEmail()
        await initDB(dbName, mailboxes)
        let body = CACHE_CONF
        for (let i = 0; i < mailboxes.length; i++) {
            let lastId = 0
            body.box = mailboxes[i]
            let currentMailboxDone = false
            while (!currentMailboxDone) {
                try {
                    body.lastId = lastId
                    let mailData = await listingForCache(body, StateManager.getToken())
                    if (_.isEmpty(mailData.mails)) {
                        currentMailboxDone = true
                        continue
                    }
                    let mails = mailData.mails
                    for (let j = 0; j < mails.length; j++) {
                        let mailObject = mails[j]
                        await saveMessageForCache(md5(dbName), md5(mailboxes[i]), mailObject)
                        if (j === mails.length - 1) {
                            lastId = mails[j].uid
                        }
                    }
                } catch (e) {
                    console.error(e)
                    console.log(`Cache done for ${mailboxes[i]}`)
                    currentMailboxDone = true
                }
            }
        }
    }

    async function saveMessageForCache(dbName, box, mailObject) {
        if (_.isEmpty(mailObject.from)) {
            return
        }

        let decryptedData = [
            decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), mailObject.subject.replaceAll("@", "\n")),
            decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), mailObject.body.body),
            decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), mailObject.body.attachmentNames),
        ]

        let [subject, body, attachmentNames] = await Promise.all(decryptedData)
        let words = subject.split(" ")

        if (body.startsWith(HTML_MESSAGE_IDENTIFIER)) {
            body = base64ToUtf8(body.split(HTML_MESSAGE_IDENTIFIER)[1])
            body = extractTextFromHTML(body)
        }

        body = body.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');
        subject = subject.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');

        words = [...subject.split(" "), ...attachmentNames.split("/"), ...body.split(" ")]

        words = words.filter(item => item !== "")

        words = [...new Set(words)]

        let formattedWords = []
        words.forEach((word) => {
            formattedWords.push(md5(word.toLowerCase()))
        })
        formattedWords.push(md5(mailObject.from))

        let cacheObject = {
            ...mailObject,
            words: formattedWords.join(","),
            attachmentNames: mailObject.body.attachmentNames,
            body: mailObject.body.body
        }

        await addEmailInCache(dbName, box, cacheObject)
    }

    function handleCurrentMsgUid(value) {
        currentMsgUid = value
        setCurrentMsgUid(currentMsgUid)
    }

    function handleCurrentMsgFrom(value) {
        currentMsgFrom = value
        setCurrentMsgFrom(currentMsgFrom)
    }

    function handleCurrentMsgTo(value) {
        currentMsgTo = value
        setCurrentMsgTo(currentMsgTo)
    }

    function handleCurrentMsgCc(value) {
        currentMsgCc = value
        setCurrentMsgCc(currentMsgCc)
    }

    function handleCurrentMsgBcc(value) {
        currentMsgBcc = value
        setCurrentMsgBcc(currentMsgBcc)
    }

    function handleCurrentMsgDate(value) {
        currentMsgDate = value
        setCurrentMsgDate(currentMsgDate)
    }

    function handleCurrentMsgSubject(value) {
        currentMsgSubject = value
        setCurrentMsgSubject(currentMsgSubject)
    }

    function handleCurrentMessageAttachments(atc) {
        currentMsgAttachments = atc
        setCurrentMsgAttachments(currentMsgAttachments)
    }

    function handleHasNextPage(value) {
        hasNextPage = value
        setHasNextPage(hasNextPage)
    }

    function handleHasPreviousPage(value) {
        hasPreviousPage = value
        setHasPreviousPage(hasPreviousPage)
    }

    function nextPage() {
        currentPage = currentPage + 1
        setCurrentPage(currentPage)
    }

    function previousPage() {
        currentPage = currentPage - 1
        setCurrentPage(currentPage)
    }

    function handleCurrentPage(value) {
        currentPage = value;
        setCurrentPage(currentPage);
    }

    function handleTotalPages(value) {
        totalPages = value
        setTotalPages(totalPages)
    }

    function handleSortOption(value) {
        sortOption = value
        setSortOption(sortOption)
    }

    function handleSortOrder(value) {
        sortOrder = value
        setSortOrder(sortOrder)
    }

    function handleMailboxes(boxes) {
        mailboxes = boxes;
        setMailboxes(mailboxes);
    }

    function handleMailData(data) {
        mailData = data
        setMailData(mailData);
    }

    function setBusy(message) {
        isWorking = true
        setIsWorking(isWorking)
    }

    function setIdle() {
        isWorking = false
        setIsWorking(isWorking)
    }

    function handleCurrentMailbox(value) {
        currentMbox = value
        setCurrentMailbox(currentMbox)
    }

    async function startingOperations() {
        const isLoggedIn = await userIsLoggedIn();
        if (!isLoggedIn) {
            redirect("/login");
        }

        const mailboxes = await listBoxes(StateManager.getToken());
        setMailboxes(mailboxes)
        const mailData = await listing(StateManager.getToken(), {
            box: currentMbox,
            pageSize: pageSize,
            pageNumber: currentPage,
            sortOption: sortOption,
            ascending: sortOrder === "ascending",
        });

        if (!_.isEmpty(mailData.mails)) {
            const mails = await decryptSubjects(mailData.mails);
            handleMailData(mails);
            handleTotalPages(mailData.totalPages);
            handleCurrentPage(mailData.pageNumber);
            handleHasNextPage(mailData.hasNext);
            handleHasPreviousPage(mailData.hasPrevious);
        } else {
            handleMailData([]);
        }
    }

    const {redirect} = useNavigation()
    useEffect(() => {
        toast.promise(startingOperations(), {pending: "loading data"}, {containerId: TOAST_CONTAINER_ID}).catch((error) => {
            console.error(error)
        })
    }, [currentMbox, currentPage, sortOption, sortOrder]);


    function handleSearch() {

    }

    async function decryptSubjects(mails) {
        for (let i = 0; i < mails.length; i++) {
            if (_.isEmpty(mails[i].subject)) {
                continue
            }
            let subject = mails[i].subject.replaceAll("@", "\n")
            mails[i].subject = await decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), subject)
        }
        return mails
    }

    async function renderMessage(key) {
        toast.loading("Rendering message", {containerId: TOAST_CONTAINER_ID})
        let mail = mailData[key]
        handleCurrentMsgFrom(mail.from)
        handleCurrentMsgTo(mail.to)
        handleCurrentMsgBcc(mail.bcc)
        handleCurrentMsgCc(mail.cc)
        handleCurrentMsgUid(mail.uid)
        handleCurrentMsgDate(formatDateForMailDisplay(mail.date))
        let messageData = await fetchMessageBody(mail.uid, currentMbox, StateManager.getToken())
        let decryptionPromises = [
            decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), messageData.body),
            decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), messageData.attachmentNames)
        ]

        let decryptedData = await Promise.all(decryptionPromises)

        let [body, attachmentNames] = decryptedData

        handleCurrentMsgSubject(mail.subject)

        if (!body.startsWith(HTML_MESSAGE_IDENTIFIER)) {
            body = `<p>${body}</p>`
        } else {
            let html = body.split(HTML_MESSAGE_IDENTIFIER)[1]
            body = base64ToUtf8(html)
        }

        let iframe = document.getElementById("render-frame");
        let doc = iframe.contentWindow.document;
        doc.open();
        doc.write(body)
        doc.close()

        let attachments = attachmentNames.split("/")
        let newAttachments = []

        if (!_.isEmpty(attachmentNames)) {
            newAttachments = attachments.filter(item => item !== "")
        }

        handleCurrentMessageAttachments(newAttachments)
        displayHeadersAndActions()
        toast.dismiss({containerId: TOAST_CONTAINER_ID})
    }

    async function downloadAttachment(name) {
        let {attachmentData} = await fetchAttachmentData(currentMsgUid, currentMbox, name, StateManager.getToken())
        let decrypted = await decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), attachmentData)
        let [attachmentHeaders, attachmentBase64] = decrypted.split("\n\n")
        let headerParts = attachmentHeaders.split("\n")
        let contentTypePart = headerParts[1]
        let contentTypeParts = contentTypePart.split(":")
        let contentType = contentTypeParts[1].split(";")[0]
        let byteArray = base64ToByteArray(attachmentBase64)
        let file = createFile([byteArray], contentType)
        downloadFile(name, file)

    }

    useEffect(() => {
        let html = ``
        currentMsgAttachments.forEach((attachment) => {
            html += `<div id="atc-${attachment}" style="flex: 1 0 150px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; box-sizing: border-box;">
            ${attachment}
            </div>`
        })
        attachmentContainerRef.current.innerHTML = html
        currentMsgAttachments.forEach((attachment) => {
            let atc = document.getElementById(`atc-${attachment}`)
            atc.onclick = () => downloadAttachment(attachment)
        })

    }, [currentMsgAttachments]);

    function extractTextFromHTML(htmlString) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || '';
    }

    function displayHeadersAndActions() {
        headerContainerRef.current.style.display = "block"
        mailActionsListRef.current.style.display = "flex"
    }


    const iconItemStyles = {
        textAlign: 'center',
        flex: 1,
        cursor: 'pointer',

    }



    const iconStyle = {
        fontSize: '1rem',
        display: 'block',
        marginBottom: '8px'
    };


    return (
        <>
            <Helmet>
                <title>Mail Page</title>
            </Helmet>
            <Header></Header>
            <div className="container-fluid" style={{height: '95vh'}}>
                {/* Navigation Section */}
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>

                    {/* Email Listing Section */}
                    <div className="col-md-2 col-lg-2 col-xl-2 p-0" style={{border: "1px solid grey"}}>
                        <ul style={{listStyleType: 'none'}} className="p-0">
                            {mailboxes.map((element) => (
                                <li key={element} id={element} className="customLi mb-2 p-1" style={{cursor: "pointer"}}
                                    onClick={() => handleCurrentMailbox(element)}>
                                    {element}
                                </li>

                            ))}
                        </ul>
                    </div>

                    <div className="col-md-3 col-lg-3 col-xl-3 p-0" style={{border: "1px solid grey"}}>
                        <StandardTextInput styles={{height: "2vh", width: "20vh"}} placeholder="Search here"
                                           onChange={handleSearch}></StandardTextInput><br></br>
                        <StandardDropdown onChange={handleSortOption} name="Sort Option"
                                          options={sortOptions}></StandardDropdown>&nbsp;
                        <StandardDropdown onChange={handleSortOrder} name="Sort Order"
                                          options={sortOrders}></StandardDropdown><br></br><br></br>
                        <div>Showing
                            Page <span>{currentPage}</span> of <span>{totalPages}  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={previousPage} disabled={!hasPreviousPage}>&lt;Page</button>
                            &nbsp;
                            <button onClick={nextPage} disabled={!hasNextPage}>Page&gt;</button>
                        </div>
                        <br></br>
                        {!isWorking &&
                            <ul id="mail-list" className="p-0" style={{listStyle: "none", width: "100%"}}>
                                {mailData.map((mailItem, key) => (
                                    <li key={mailItem.uid} id={mailItem.uid} onClick={() => renderMessage(key)}
                                        className="mb-2 mailListLi">
                                        <div className={Styles.mailListItem}>
                                            <span>
                                                <strong>Subject:</strong> {mailItem.subject.length > 10
                                                ? `${mailItem.subject.substring(0, 10)}...`
                                                : mailItem.subject}
                                            </span>
                                            <br/>
                                            <span><strong>From:</strong> {mailItem.from.length > 10
                                                ? `${mailItem.from.substring(0, 10)}...`
                                                : mailItem.from}</span>
                                            <br/>
                                            <span><strong>On:</strong> {formatDateForMailDisplay(mailItem.date)}</span>
                                        </div>
                                    </li>

                                ))}
                                {mailData.length === 0 && (<span>Nothing to show</span>)}
                            </ul>
                        }
                    </div>

                    {/* Email Content Section */}

                    <div style={{overflow: "hidden", position: "relative", width: "600px"}}
                         className="col-md-6 col-lg-6 col-xl-7">
                        <br></br>
                        <div ref={mailActionsListRef}
                             style={{display: "none", justifyContent: 'space-between', margin: '0 auto'}}
                             className="icon-list">
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-reply"></i>
                                <p>Reply</p>
                            </div>
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-reply-all"></i>
                                <p>Reply All</p>
                            </div>
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-share"></i>
                                <p>Forward</p>
                            </div>
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-copy"></i>
                                <p>Copy</p>
                            </div>
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-folder"></i>
                                <p>Move</p>
                            </div>
                            <div style={iconItemStyles} className={Styles.iconItem}>
                                <i style={iconStyle} className="fas fa-trash"></i>
                                <p>Delete</p>
                            </div>
                        </div>

                        <div ref={headerContainerRef} style={{display: "none", width: "100%"}}
                             className="message-render-headers-section">
                            <span><strong>From</strong>: {currentMsgFrom} </span><br></br>
                            <span><strong>To</strong>: {currentMsgTo} </span><br></br>
                            <span><strong>CC:</strong> {currentMsgBcc} </span><br></br>
                            <span><strong>BCC:</strong> {currentMsgBcc} </span><br></br>
                            <span><strong>On:</strong> {currentMsgDate} </span><br></br>
                            <span><strong>Subject:</strong> {currentMsgSubject} </span><br></br>

                        </div>
                        <br></br>
                        <div id="attachment-names"
                             ref={attachmentContainerRef}
                             style={{display: "flex", flexWrap: "wrap", gap: "5px", width: "100%"}}
                             className="attachment-names">
                        </div>
                        <br></br>
                        <iframe style={{
                            width: "100%",
                            display: "block",
                            height: "100%",
                            border: "none",
                            overflowY: "scroll"
                        }}
                                id="render-frame">

                        </iframe>
                    </div>
                </div>
            </div>
        </>
    );
}