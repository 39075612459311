import React from 'react';
import {useNavigation} from '../../hooks/navigation';
import './index.css';
import {logoutCall} from "../../server-calls";
import {toast} from 'react-toastify';
import StateManager from "../../redux/StateManager";
import {TOAST_CONTAINER_ID} from "../../utils/constants";

const Navigation = () => {
    const {redirect} = useNavigation();
    const logout = async () => {
        toast.promise(logoutCall(StateManager.getToken()), {
            pending: "Logging you out",
            success: "Log out success",
            error: "Server error occurred"
        }, {containerId: TOAST_CONTAINER_ID}).then(() => {
            redirect("/login")
        })
    }

    return (
        <div style={{border: "1px solid grey", height: "95vh"}} className="w-1/5 bg-gray-200">
            <ul style={{listStyleType: 'none'}} className="p-0">
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/compose")}>
                    Compose
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/mail")}>
                    Mail
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/domains")}>
                    Domains
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/aliases")}>
                    Aliases
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/filters")}>
                    Filters
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/settings")}>
                    Settings
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={logout}>
                    Logout
                </li>
            </ul>
        </div>
    );
};

export default Navigation;
