import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";
import {useState} from "react";
import {convertFileToBase64} from "../utils/utils";
import {encryptMessage, generateRFC822Message} from "../mail/utils";
import StateManager from "../redux/StateManager"
import {fetchPublicKeys, sendMessageToBackend} from "../server-calls/index";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from 'react-toastify';
export default function Compose() {

    const inputWrapperStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    };

    const tagStyle = {
        display: 'inline-block',
        background: '#e2e6ea',
        borderRadius: '15px',
        padding: '5px 10px',
        margin: '5px',
    };

    const removeButtonStyle = {
        marginLeft: '8px',
        background: 'transparent',
        border: 'none',
        color: '#dc3545',
        cursor: 'pointer'
    };

    const inputStyle = {
        border: 'none',
        outline: 'none',
        flexGrow: 1,
    };

    let [to, setTo] = useState("");
    let [cc, setCc] = useState("");
    let [bcc, setBcc] = useState("");
    let [toList, setToList] = useState([]);
    let [ccList, setCcList] = useState([]);
    let [bccList, setBccList] = useState([]);
    let [messageBody, setMessageBody] = useState("");
    let [subject, setSubject] = useState("");
    let [isHtml, setIsHtml] = useState(false);
    let [isEncrypt, setIsEncrypt] = useState(false);
    let [attachments, setAttachments] = useState([]);
    let [isBusy, setIsBusy] = useState(false)
    let [receivers, setReceivers] = useState([])

    const handleToChange = (e) => {
        const value = e.target.value;
        setTo(value);
        if (value.includes(',')) {
            const newEmails = value.split(',').map(email => email.trim()).filter(email => email !== '');
            const validEmails = newEmails.filter(validateEmail);
            if (validEmails.length > 0) {
                setToList([...toList, ...validEmails]);
                setReceivers([...receivers, ...validEmails]);
                setTo("")
            }
        }
    };

    const handleCcChange = (e) => {
        const value = e.target.value;
        setCc(value);
        if (value.includes(',')) {
            const newEmails = value.split(',').map(email => email.trim()).filter(email => email !== '');
            const validEmails = newEmails.filter(validateEmail);
            if (validEmails.length > 0) {
                setCcList([...ccList, ...validEmails]);
                setReceivers([...receivers, ...validEmails]);
                setCc("")
            }
        }
    };

    const handleBccChange = (e) => {
        const value = e.target.value;
        setBcc(value);
        if (value.includes(',')) {
            const newEmails = value.split(',').map(email => email.trim()).filter(email => email !== '');
            const validEmails = newEmails.filter(validateEmail);
            if (validEmails.length > 0) {
                setBccList([...bccList, ...validEmails]);
                setReceivers([...receivers, ...validEmails]);
                setBcc("")
            }
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRemoveEmail = (list, setList, index) => {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
    };

    const handleIsBusy = (value) => {
        isBusy = value
        setIsBusy(isBusy)
    }

    const handleFileUpload = async (e) => {
        let files = Array.from(e.target.files)
        let built = []
        for (let i = 0; i < files.length; i++) {
            let type = files[i].type
            if (_.isEmpty(type))
                type = "text/plain"
            built.push({
                name: files[i].name,
                content: await convertFileToBase64(files[i]),
                type
            })
        }
        attachments = built
        setAttachments(attachments);
    };

    const handleEncryptChange = () => {
        setIsEncrypt(!isEncrypt);
    };

    const handleSubject = (value) => {
        subject = value
        setSubject(subject)
    }

    const handleBody = (value) => {
        messageBody = value
        setMessageBody(messageBody)
    }


    const sendMessage = async () => {
        toast.loading("sending message")
        try {
            if (_.isEmpty(toList)) {
                alert("Please enter to emails properly.")
            }

            let backendCallBody = {
                to: toList,
                cc: ccList,
                bcc: bccList,
                message: messageBody,
                subject: subject,
                isHtml,
                attachments,
                isEncrypted: isEncrypt
            }

            if (isEncrypt) {
                receivers.push(StateManager.getUserEmail())
                let publicKeys = await fetchPublicKeys(receivers, StateManager.getToken())
                let notFound = publicKeys.notFound
                publicKeys = publicKeys.found
                try {
                    const {body, encryptedSubject} = await encryptMessage({
                        body: messageBody,
                        subject,
                        isHtml,
                        attachments,
                        publicKeys
                    })
                    backendCallBody.message = body
                    backendCallBody.subject = encryptedSubject
                    backendCallBody.isEncrypted = true
                    backendCallBody.isHtml = false
                    backendCallBody.attachments = []
                } catch (error) {
                    toast.dismiss()
                    toast.error("error encrypting message")
                    console.error(error)
                }
            }


            await sendMessageToBackend(backendCallBody, StateManager.getToken())
            handleIsBusy(false)
            toast.success("message sent")
        } catch (e) {
            toast.dismiss()
            toast.error("error sending message")
            console.log(e)
        }
    }

    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '95vh'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-11 col-lg-11 col-xl-11 p-0">
                        <div style={{marginLeft: "15px", marginTop: "15px"}} className="row">
                            <div className="col-md-8 col-lg-8 col-xl-8">
                                {/* To Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">To:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {toList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(toList, setToList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            value={to}
                                            onChange={handleToChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* CC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Cc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {ccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(ccList, setCcList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            value={cc}
                                            onChange={handleCcChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* BCC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Bcc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {bccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(bccList, setBccList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            value={bcc}
                                            onChange={handleBccChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Subject:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        <input
                                            type="text"
                                            onChange={(e) => handleSubject(e.target.value)}
                                            placeholder="Subject"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* Message Body */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Message:</label>
                                    <div className="col-sm-6">
                                        <textarea
                                            value={messageBody}
                                            onChange={(e) => handleBody(e.target.value)}
                                            rows={15}
                                            style={{width: '90vh', padding: '10px', borderRadius: '4px'}}
                                        />
                                    </div>
                                </div>

                                {/* Is HTML Toggle Button */}
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button
                                            className={`btn ${isHtml ? 'btn-success' : 'btn-outline-success'}`}
                                            onClick={() => setIsHtml(!isHtml)}
                                            disabled={isBusy}
                                        >
                                            {isHtml ? 'HTML Enabled' : 'Enable HTML'}
                                        </button>
                                    </div>
                                </div>


                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button className='btn btn-primary' onClick={sendMessage}
                                                disabled={isBusy}>
                                            Send Message
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div style={{width: "160px"}} className="col-md-4 col-lg-4 col-xl-4">
                                {/* Encrypt Checkbox */}
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-12">
                                        <input
                                            type="checkbox"
                                            checked={isEncrypt}
                                            onChange={handleEncryptChange}
                                        />
                                        <label className="ml-2">Encrypt Message</label>
                                    </div>
                                </div>

                                {/* Attachments Upload */}
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-12">
                                        <label>Attachments:</label>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleFileUpload}
                                        />
                                        {/*<ul className="mt-2">*/}
                                        {/*    {attachments.length > 0 && attachments.map((file, index) => (*/}
                                        {/*        <li key={index}>{file.name}</li>*/}
                                        {/*    ))}*/}
                                        {/*</ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
}
