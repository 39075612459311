import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";

export default function Aliases() {
    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '95vh'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                </div>
            </div>
        </>
    );
}