const BASE_URL = "https://api.privmail.com:2100"
const SOCKET_BASE_URL = "wss://api.privmail.com:2100"
// const BASE_URL = "http://localhost:4040"
const endPoints = {
    register: {url: `${BASE_URL}/v1/user/register`, method: "POST"},
    login: {url: `${BASE_URL}/v1/user/login`, method: "POST"},
    checkUsernameDuplication: {url: `${BASE_URL}/v1/user/check-duplicate-user`, method: "POST"},
    checkSession: {url: `${BASE_URL}/v1/user/session-status`, method: "GET"},
    logout: {url: `${BASE_URL}/v1/user/logout`, method: "GET"},
    emailListing: {url: `${BASE_URL}/v1/mail/listing`, method: "POST"},
    boxesListing: {url: `${BASE_URL}/v1/mail/list-boxes`, method: "GET"},
    sendMessageOnSocket: {url: `${SOCKET_BASE_URL}/ws/send-message`},
    fetchReceiversPublicKeys: {url: `${BASE_URL}/v1/user/fetch-public-keys`, method: "POST"},
    sendMessageHttp: {url: `${BASE_URL}/v1/mail/send`, method: "POST"},
    listingForCache: {url: `${BASE_URL}/v1/mail/listing-for-cache`, method: "POST"},
    fetchMessageBodyForRender: {url: `${BASE_URL}/v1/mail/fetch-body`, method: "POST"},
    fetchAttachmentData: {url: `${BASE_URL}/v1/mail/fetch-attachment-data`, method: "POST"},
}

export function getEndpoints() {
    return endPoints
}