import React from 'react';
import defaultStyles from "../../../styles/main.module.css"
const StandardTextInput = ({value, onChange, placeholder = '', styles}) => {
    return (
        <input
            className={defaultStyles.standardTextField}
            type='text'
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            style={styles}
        />
    );
};

export default StandardTextInput;
