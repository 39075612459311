import axios from "axios";
import {getEndpoints} from "../utils/server-endpoints";

export function prepareAuthHeader(token) {
    return {Authorization: `Bearer ${token}`}
}

async function sendCall(url, method, data, headers = {}) {
    let resp = await axios({url, method, data, headers})
    return resp.data
}

export async function sessionValid(token) {
    try {
        const {url, method} = getEndpoints().checkSession
        const headers = prepareAuthHeader(token)
        await sendCall(url, method, {}, headers)
        return Promise.resolve(true)
    } catch (error) {
        return Promise.resolve(false)
    }
}

export async function sendLoginRequest(body) {
    const {url, method} = getEndpoints().login
    try {
        let data = await sendCall(url, method, body)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("invalid credentials")
    }
}

export async function sendRegisterRequest(body) {
    try {
        const {url, method} = getEndpoints().register
        await sendCall(url, method, body)
        return Promise.resolve(true)
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function checkUsernameDuplication(username) {
    try {
        const {url, method} = getEndpoints().checkUsernameDuplication
        const data = await sendCall(url, method, {username})
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function logoutCall(token) {
    try {
        const {url, method} = getEndpoints().logout
        const headers = prepareAuthHeader(token)
        await sendCall(url, method, {}, headers)
        return true
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function listing(token, body) {
    try {
        const {url, method} = getEndpoints().emailListing
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function listBoxes(token) {
    try {
        const {url, method} = getEndpoints().boxesListing
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function sendMessageToBackend(body, token) {
    try {
        try {
            const {url, method} = getEndpoints().sendMessageHttp
            const headers = prepareAuthHeader(token)
            let data = await sendCall(url, method, body, headers)
            return data.content
        } catch (error) {
            console.error(error)
            return Promise.reject(error.message)
        }
    } catch (e) {

    }
}

export async function sendMessageToBackendUsingSocket(jsonString) {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket(getEndpoints().sendMessageOnSocket.url);
        socket.onopen = () => {
            socket.send(jsonString);
            socket.close();
        };

        socket.onclose = () => {
            resolve()
        };

        socket.onerror = (error) => {
            reject(error)
        };
    })
}

export async function fetchPublicKeys(receivers, token) {
    try {
        const {url, method} = getEndpoints().fetchReceiversPublicKeys
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {receivers}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function fetchMessageBody(uid, box, token) {
    try {
        const {url, method} = getEndpoints().fetchMessageBodyForRender
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {uid, box}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function fetchAttachmentData(uid, box, attachmentName, token) {
    try {
        const {url, method} = getEndpoints().fetchAttachmentData
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {uid, box, attachmentName}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function listingForCache(body, token) {
    try {
        const {url, method} = getEndpoints().listingForCache
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}