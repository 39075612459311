import StandardButton from "../components/basic/StandardButton";
import {useEffect, useState} from "react";
import StandardTextInput from "../components/basic/StandardTextInput";
import styles from "../styles/main.module.css";
import "react-toastify/dist/ReactToastify.css";
import StandardPasswordInput from "../components/basic/StandardPasswordInput";
import {
    addTextOnImage,
    downloadFile,
    createFile,
    createZip,
    createImage,
    prepareUsernameForKeyImageText, readTextFromFileUpload, userIsLoggedIn
} from "../utils/utils";

import _ from "lodash"

import StandardRadioGroup from "../components/basic/StandardRadioGroup";
import {
    KEY_IMAGE_PATH,
    REGISTER_FORM_RADIO_DATA,
    REGISTER_KEY_DOWNLOAD_TYPES,
    REGISTER_TYPES,
    VALIDATIONS
} from "../utils/constants";
import StandardFileInput from "../components/basic/StandardFileInput";
import {toast, ToastContainer} from 'react-toastify';
import {generatePGPKeys, getKeyFingerprint} from "../business/encryption/pgp";
import {generateSha512Hash} from "../business/hashing";
import {checkUsernameDuplication, sendRegisterRequest} from "../server-calls/index";
import {useNavigation} from "../hooks/navigation";

export default function Register() {

    const {redirect} = useNavigation()

    useEffect(() => {
        userIsLoggedIn().then((isLoggedIn) => {
            if (isLoggedIn) {
                redirect("/mail");
            }
        })
    }, [])

    const [formOptions] = useState([
        REGISTER_FORM_RADIO_DATA.generateKeys,
        REGISTER_FORM_RADIO_DATA.uploadPublicKey,
        REGISTER_FORM_RADIO_DATA.emailPass
    ])
    let [formOption, setFormOption] = useState(REGISTER_FORM_RADIO_DATA.generateKeys.value);

    let [keyDownloadOptions] = useState([
        REGISTER_KEY_DOWNLOAD_TYPES.image,
        REGISTER_KEY_DOWNLOAD_TYPES.text
    ])

    let [keyDownloadOption, setKeyDownloadOption] = useState(REGISTER_KEY_DOWNLOAD_TYPES.image.value)
    let [registerType, setRegisterType] = useState(REGISTER_TYPES.key);
    let [username, setUsername] = useState("");
    let [passphrase, setPassphrase] = useState("");
    let [publicKey, setPublicKey] = useState("");
    let [isWorking, setIsWorking] = useState(false)
    let [allDone, setAllDone] = useState(false)
    let [fullEmail, setFullEmail] = useState("")

    function handleUsername(value) {
        username = value
        setUsername(username)
    }

    function handlePassphrase(value) {
        passphrase = value
        setPassphrase(passphrase)
    }

    function handleFormOption(value) {
        formOption = value
        setFormOption(formOption)
    }

    function handleKeyDownloadTypeOptions(value) {
        keyDownloadOption = value
        setKeyDownloadOption(value)
    }

    async function handlePublicKeyUpload(files) {
        publicKey = await readTextFromFileUpload(files[0])
        setPublicKey(publicKey)
    }

    function handleLoginRedirect() {
        return redirect("/login")
    }

    function handleEmail(value) {
        fullEmail = value
        setFullEmail(fullEmail)
    }

    function handleRegisterType(value) {
        registerType = value
        setRegisterType(registerType)
    }

    function handleAllDone(value) {
        allDone = value
        setAllDone(allDone)
    }

    function setBusy(message) {
        toast.loading(message)
        isWorking = true
        setIsWorking(isWorking)
    }

    function setIdle() {
        toast.dismiss()
        isWorking = false
        setIsWorking(isWorking)
    }

    async function handleSubmit() {
        try {
            setBusy("registering your account")
            let email = await performValidations()
            handleEmail(email)
            switch (formOption) {
                case REGISTER_FORM_RADIO_DATA.emailPass.value:
                    handleRegisterType(REGISTER_TYPES.emailPass)
                    await emailPassRegister()
                    break;
                case REGISTER_FORM_RADIO_DATA.generateKeys.value:
                    handleRegisterType(REGISTER_TYPES.key)
                    await generateKeyRegister()
                    break;
                case REGISTER_FORM_RADIO_DATA.uploadPublicKey.value:
                    handleRegisterType(REGISTER_TYPES.key)
                    await uploadKeyRegister()
                    break;
            }
            setIdle()
            toast.success("user registered successfully.");
            handleAllDone(true)
        } catch (e) {
            setIdle()
            console.error(e)
            toast.error(e.message)
        }
    }


    async function uploadKeyRegister() {
        await getKeyFingerprint(publicKey)
        await sendRegisterRequest({registerType, publicKey, username})
    }

    async function emailPassRegister() {
        let {privateKey, publicKey} = await generatePGPKeys(fullEmail, passphrase)
        let accessToken = generateSha512Hash(passphrase)
        await sendRegisterRequest({registerType, username, accessToken, privateKey, publicKey})
    }

    async function generateKeyRegister() {
        const registerType = "key"
        let {privateKey, publicKey} = await generatePGPKeys(fullEmail, passphrase)
        await sendRegisterRequest({registerType, username, publicKey})
        switch (keyDownloadOption) {
            case REGISTER_KEY_DOWNLOAD_TYPES.text.value:
                let fileLink = createFile([privateKey], "text/plain")
                downloadFile(fileLink, `${fullEmail}.key`)
                break;
            case REGISTER_KEY_DOWNLOAD_TYPES.image.value:
                let preparedEmailForImage = prepareUsernameForKeyImageText(fullEmail)
                let keyImage = await createImage(KEY_IMAGE_PATH)
                let preparedImage = await addTextOnImage(preparedEmailForImage, keyImage)
                let privateKeyZip = await createZip(privateKey, `privateKey.key`)
                let preparedKeyImageLink = createFile([preparedImage, privateKeyZip], "image/jpeg")
                downloadFile(preparedKeyImageLink, `${preparedEmailForImage}.jpeg`)
                break;
        }
    }

    async function performValidations() {
        switch (formOption) {
            case REGISTER_FORM_RADIO_DATA.generateKeys.value:
                await validateUsername()
                await validatePassphrase()
                break;
            case REGISTER_FORM_RADIO_DATA.emailPass.value:
                await validateUsername()
                await validatePassphrase()
                break;
            case REGISTER_FORM_RADIO_DATA.uploadPublicKey.value:
                await validateUsername()
                await validatePublicKeyUpload()
                break;
        }

        let duplicateResp = await checkUsernameDuplication(username)
        if (duplicateResp.userExists) {
            throw new Error("user already exists")
        }

        return Promise.resolve(duplicateResp.email)
    }

    async function validateUsername() {
        if (_.isEmpty(username) || username.length < VALIDATIONS.USERNAME_LENGTH) {
            throw new Error("username must be minimum 3 characters long")
        }

        if (username.includes("@")) {
            throw new Error("please enter a username not an email address")
        }

        return Promise.resolve()
    }

    async function validatePassphrase() {
        if (_.isEmpty(passphrase) || passphrase.length < VALIDATIONS.PASSPHRASE_LENGTH) {
            throw new Error("passphrase should be minimum 8 characters long")
        }
        return Promise.resolve()
    }

    async function validatePublicKeyUpload() {
        if (_.isEmpty(publicKey)) {
            throw new Error("public key is required.")
        }
        return Promise.resolve()
    }

    function getButtonStyles() {
        if (isWorking) {
            return {background: "gray"}
        } else {
            return {}
        }
    }

    return (
        <main>
            <div className={styles.container}>
                <div style={{width: "400px"}}>
                    <h1>Register</h1>
                    <StandardRadioGroup options={formOptions} onChange={handleFormOption}></StandardRadioGroup>
                    {formOption === REGISTER_FORM_RADIO_DATA.uploadPublicKey.value && (
                        <StandardFileInput width="100%" name="Public Key"
                                           onChange={handlePublicKeyUpload}></StandardFileInput>)}

                    {(formOption === REGISTER_FORM_RADIO_DATA.generateKeys.value) && (<>
                            <br></br>
                            <label>Key Download Type</label>
                            <br></br><br></br>
                            <StandardRadioGroup onChange={handleKeyDownloadTypeOptions}
                                                options={keyDownloadOptions}></StandardRadioGroup>
                            <br></br>
                        </>
                    )}

                    <StandardTextInput width="100%" onChange={handleUsername}
                                       placeholder="Username without domain"></StandardTextInput>
                    {(formOption === REGISTER_FORM_RADIO_DATA.generateKeys.value || formOption === REGISTER_FORM_RADIO_DATA.emailPass.value) && (
                        <StandardPasswordInput onChange={handlePassphrase} width="100%"
                                               placeholder="Passphrase"></StandardPasswordInput>
                    )}

                    {!allDone && (
                        <StandardButton width="100%" onClick={handleSubmit} disabled={isWorking}
                                        innerText="Register"></StandardButton>)}

                    {allDone && (<StandardButton width="100%" onClick={handleLoginRedirect}
                                                 innerText="To Login Page"></StandardButton>)}

                    <br></br><br></br>


                    <p>Already have an account ? Login <a href="/login">here</a></p><br></br>
                </div>
            </div>
            <ToastContainer/>
        </main>
    );
}