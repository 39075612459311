import {
    setIsLoggedIn,
    setPassphrase,
    setPrivateKey,
    setToken,
    setUserId,
    setUserEmail,
    setSelectedBox,
    cleanState,
    store,
    setIsBusy, setBoxesCached
} from './store';
class StateManager {

    static getState() {
        return store.getState();
    }

    static getUserId() {
        return this.getState().userId;
    }

    static getUserEmail() {
        return this.getState().userEmail;
    }

    static getPrivateKey() {
        return this.getState().privateKey;
    }

    static getPassphrase() {
        return this.getState().passphrase;
    }

    static getToken() {
        return this.getState().token;
    }

    static isLoggedIn() {
        return this.getState().isLoggedIn;
    }

    static isBusy() {
        return this.getState().isBusy;
    }

    static setUserId(userId) {
        store.dispatch(setUserId(userId));
    }

    static setIsBusy(boolean){
        store.dispatch(setIsBusy(boolean))
    }

    static setUserEmail(userEmail) {
        store.dispatch(setUserEmail(userEmail));
    }

    static setPrivateKey(privateKey) {
        store.dispatch(setPrivateKey(privateKey));
    }

    static setPassphrase(passphrase) {
        store.dispatch(setPassphrase(passphrase));
    }

    static setToken(token) {
        store.dispatch(setToken(token));
    }

    static setIsLoggedIn(isLoggedIn) {
        store.dispatch(setIsLoggedIn(isLoggedIn));
    }

    static cleanState() {
        store.dispatch(cleanState());
    }

    static setSelectedBox(box){
        store.dispatch(setSelectedBox(box))
    }

    static setBoxesCached(boxes){
        store.dispatch(setBoxesCached(boxes))
    }

    static boxesCached(boxes){
        return this.getState().boxesCached
    }
}

export default StateManager;