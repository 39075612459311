import * as openpgp from "openpgp"


export async function generatePGPKeys(email, passphrase) {
    let {privateKey, publicKey} = await openpgp.generateKey({
        type: 'rsa',
        rsaBits: 2048,
        userIDs: [{email}],
        passphrase,
    });

    privateKey = privateKey.replace(/^\n+/, '').replace(/\n+$/, '')
    publicKey = publicKey.replace(/^\n+/, '').replace(/\n+$/, '')

    return {publicKey, privateKey}
}

export async function encryptPGPMessage(encryptionKeys, plainText) {
    return openpgp.encrypt({
        message: await createMessage(plainText),
        encryptionKeys
    });
}

export async function decryptPGPMessage(privateKeyArmored, passphrase, messageArmored) {
    const message = await readMessage(messageArmored)
    const privateKey = await decryptPrivateKey(privateKeyArmored, passphrase);
    const {data: decrypted} = await openpgp.decrypt({
        message,
        decryptionKeys: privateKey
    });
    return decrypted
}

export async function signPGPMessage(privateKeyArmored, passphrase, plainText) {
    const privateKey = await decryptPrivateKey(privateKeyArmored, passphrase)
    const unsignedMessage = await openpgp.createCleartextMessage({text: plainText});
    return openpgp.sign({
        message: unsignedMessage,
        signingKeys: privateKey
    });
}

function readPrivateKey(armoredKey) {
    return openpgp.readPrivateKey({armoredKey})
}

export function readPublicKey(armoredKey) {
    return openpgp.readKey({armoredKey})
}

function createMessage(text) {
    return openpgp.createMessage({text})
}

function readMessage(armoredMessage) {
    let options = {armoredMessage}
    return openpgp.readMessage(options)
}

export async function decryptPrivateKey(armoredKey, passphrase) {
    return openpgp.decryptKey({
        privateKey: await readPrivateKey(armoredKey),
        passphrase
    })
}

export async function getKeyFingerprint(publicKeyArmored) {
    return (await readPublicKey(publicKeyArmored)).getFingerprint().toLowerCase()
}