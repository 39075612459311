import {openDB} from 'idb';
import md5 from 'md5';

import {MailFieldsForCache} from "../mail/utils";

export async function initDB(dbName, boxes = []) {
    dbName = md5(dbName);
    return openDB(dbName, 1, {
        upgrade(db) {
            boxes.forEach((box) => {
                const boxName = md5(box);
                if (!db.objectStoreNames.contains(boxName)) {
                    let store = db.createObjectStore(boxName, { keyPath: MailFieldsForCache.uid });
                    store.createIndex(MailFieldsForCache.uid, MailFieldsForCache.uid, { unique: true });
                }
            });
        }
    });
}

export async function addEmailInCache(dbName, box, message) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    await store.put(message);
    await tx.done;
}

export async function deleteMessageFromBox(dbName, box, uid) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    await store.delete(uid);
    await tx.done;
}

export async function readMessageByUid(dbName, box, uid) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readonly');
    const store = tx.objectStore(box);
    const message = await store.get(uid);
    await tx.done;
    return message;
}

export async function moveMessageBetweenBoxes(dbName, sourceBox, targetBox, uid) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction([sourceBox, targetBox], 'readwrite');
    const sourceStore = tx.objectStore(sourceBox);
    const targetStore = tx.objectStore(targetBox);
    const message = await sourceStore.get(uid);
    if (message) {
        await targetStore.put(message);
        await sourceStore.delete(uid);
    }
    await tx.done;
}

export async function updateMessage(dbName, box, uid, newValues) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    const message = await store.get(uid);
    if (message) {
        Object.assign(message, newValues);
        await store.put(message);
    }
    await tx.done;
}

export async function fetchLatestMessage(dbName, box) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readonly');
    const store = tx.objectStore(box);
    const latestMessage = await store.index('uid').getAll(undefined, IDBKeyRange.lowerBound(0, true));
    await tx.done;
    return latestMessage.length > 0 ? latestMessage[latestMessage.length - 1] : null;
}

export async function countRecordsInBox(dbName, box) {
    const db = await openDB(dbName, 1);
    const tx = db.transaction(box, 'readonly');
    const count = await tx.objectStore(box).count();
    await tx.done;
    return count;
}

export async function cacheBoxes(userId, boxes) {

}
