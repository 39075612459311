import React, { useState } from 'react';

const StandardRadioGroup = ({ options, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(options[0].value);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            {options.map((option) => (
                <label key={option.value} style={{ marginRight: '10px' }}>
                    <input
                        type="radio"
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleChange}
                    />
                    {option.text}
                </label>
            ))}
        </div>
    );
};

export default StandardRadioGroup;
