import React from 'react';
import defaultStyles from "../../../styles/main.module.css";

const StandardFileInput = ({name, onChange, multiple = false}) => {
    const handleFileChange = (event) => {
        const files = event.target.files;
        if (onChange) {
            onChange(files);
        }
    };

    return (
        <input
            className={defaultStyles.standardTextField}
            type="file"
            name={name}
            onChange={handleFileChange}
            multiple={multiple}
        />
    );
};

export default StandardFileInput;
