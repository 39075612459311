import React from 'react';
import styles from "./index.module.css"

const StandardButton = ({ onClick, innerText, customStyles = {}, disabled = false }) => {
    const buttonStyles = { ...customStyles };

    if (disabled) {
        buttonStyles.background = "gray"
    }

    return (
        <button className={styles.StandardButton} onClick={onClick} style={buttonStyles} disabled={disabled}>
            {innerText}
        </button>
    );
};

export default StandardButton;
