import {configureStore, createSlice} from '@reduxjs/toolkit';
import {persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        userId: null,
        userEmail: null,
        privateKey: null,
        passphrase: null,
        token: null,
        isLoggedIn: false,
        selectedBox: "INBOX",
        isBusy: false,
        boxesCached: [],
        totalMessages: 0,
        cachedMessages: 0,
    },
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setPrivateKey: (state, action) => {
            state.privateKey = action.payload;
        },
        setPassphrase: (state, action) => {
            state.passphrase = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setSelectedBox: (state, action) => {
            state.selectedBox = action.payload;
        },
        setIsBusy: (state, action) => {
            state.isBusy = action.payload;
        },

        setBoxesCached: (state, action) => {
            state.boxesCached = action.payload;
        },

        setTotalMessages: (state, action) => {
            state.totalMessages = action.payload;
        },

        setCachedMessages: (state, action) => {
            state.cachedMessages = action.payload;
        },

        cleanState: (state) => {
            state.userId = null;
            state.userEmail = null;
            state.privateKey = null;
            state.passphrase = null;
            state.token = null;
            state.isLoggedIn = false;
            state.isBusy = false
            state.boxesCached = []
            state.totalMessages = 0
            state.cachedMessages = 0
        }
    }
});

export const {
    setUserId,
    setUserEmail,
    setPrivateKey,
    setPassphrase,
    setToken,
    setIsLoggedIn,
    cleanState,
    setSelectedBox,
    setIsBusy,
    setBoxesCached,
    setTotalMessages,
    setCachedMessages
} = sessionSlice.actions;


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, sessionSlice.reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)
